import { Component } from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Unsubscribe } from 'redux';
import EditorApp from '../components/projects/edit/EditorApp';
import EditorActions from '../actions/EditorActions';
import { getCurrentFile, getFileById } from '../reducers/editor';
import * as CL2Types from '../CL2Types';
import { withErrorBoundaryFallback } from '../components/error/withErrorBoundaryFallback';
import { BroadcastActions } from '../components/BroadcastActions';
import {
  addYEventHandler,
  addYStateHandler,
  emitYEvent,
  removeYEventHandler,
  removeYStateHandler,
  websocketProvider,
  yDoc,
} from '../../y';

interface Props {
  user: CL2Types.EditorUser;
  files: CL2Types.ProjectFile[];
  exist_last_opened_file: boolean;
  currentFile: CL2Types.CurrentFile;
  compileTargetFile: CL2Types.ProjectFile;
  compiling: boolean;
  waiting: boolean;
  project: CL2Types.EditorProject;
  conflictFileDialog: boolean;
  conflictFiles: Array<CL2Types.ConflictFile>;
  fileLoading: boolean;
  openSnackbar: boolean;
  loading: boolean;
  pdfPageInfo: CL2Types.PdfPageInfo;
  editorPositionInfo: CL2Types.EditorPositionInfo;
  showSynctexPdfIndicator: boolean;
  showSynctexEditorIndicator: boolean;
  snackbarMessage: string;
  syncing: boolean;
  upload: CL2Types.UploadStatuses;
  restoreDialog: CL2Types.RestoreDialog;
  network: CL2Types.NetworkStatus;
  result: CL2Types.Result;
  texDocSearching: boolean;
  documentResult: CL2Types.DocumentResult;
  previewFile: CL2Types.PreviewFile;
  error: CL2Types.EditorError;
  tabValue: number;
  bibtexItems: CL2Types.BibtexItems;
  selectTargetDialog: { open: boolean; files: CL2Types.ProjectFile[] };
}
interface State {
  syncDisable: boolean;
  saveDisable: boolean;
  compileDisable: boolean;
}
class EditorAppContainer extends Component<Props, State> {
  /*
   * private vars
   */

  // キー入力位置1文字ごとに頻繁に書き換える変数をstateに入れると処理が重くなるのでprivate var 扱い
  private editorContent = '';

  private cancelDelayedSaveAndCompile: () => void = function () {
    let cancelHandler: () => void;
    while (this.cancelDelayedSaveAndCompileHandles.length > 0) {
      cancelHandler = this.cancelDelayedSaveAndCompileHandles.pop();
      cancelHandler();
    }
  };

  private cancelDelayedSaveAndCompileHandles: Array<() => void> = [];

  private unsubscribeChannel: Unsubscribe;

  /*
   * methods
   */

  private runSaveAndCompile = (file, isAutoCompile) => {
    if (!this.props.project?.permission.edit) {
      return;
    }
    this.handleSave(file).then(
      () => {
        if (
          !isAutoCompile ||
          (this.editorContent !== '' &&
            this.props.project &&
            this.props.project.auto_compile)
        ) {
          this.runCompile();
        }
      },
      () => {
        // handle error
        this._saveAndCompile(file, isAutoCompile);
      },
    );
  };

  private _saveAndCompile = (() => {
    const delayedSaveAndCompile = _.debounce(this.runSaveAndCompile, 2000);
    this.cancelDelayedSaveAndCompileHandles.push(delayedSaveAndCompile.cancel);
    return (file, isAutoCompile) => {
      delayedSaveAndCompile(file, isAutoCompile);
    };
  })();

  private handleSave = (file) => {
    if (this.props.project?.collab) {
      // Stop save if collab is enabled because it's done in server job
      return Promise.resolve();
    }
    if (!this.props.project?.permission.edit) {
      return Promise.reject();
    }
    if (this.props.waiting) {
      // save 中はすぐに新しいsaveの実行をしない
      return Promise.reject();
    }
    if (this.state.saveDisable) {
      // 変更がないのでsave必要なし
      return Promise.resolve();
    }
    this.setState({
      saveDisable: true,
    });
    return EditorActions.updateFile(file.id, { content: this.editorContent });
  };

  private saveHandler = () => {
    this.handleSave(this.props.currentFile).catch(() => {});
  };

  private runCompile = () => {
    if (this.props.project && this.props.project.compile_target_file_id) {
      if (this.props.project?.collab) {
        emitYEvent('compile', { at: new Date().getTime() });
      } else {
        EditorActions.compile();
      }
    }
  };

  // misc handlers

  private onEditorChange = (newContent) => {
    if (!this.props.currentFile) {
      return;
    }
    this.editorContent = newContent;
    if (this.state.saveDisable) {
      this.setState({
        saveDisable: false,
      });
    }
    this._saveAndCompile(this.props.currentFile, true);
  };

  private onBeforeOpenFile = () => {
    this.cancelDelayedSaveAndCompile();

    // immediately save
    if (!this.state.saveDisable) {
      this.runSaveAndCompile(this.props.currentFile, true);
    }
  };

  private createFile = (name, belongs, isFolder) =>
    EditorActions.createFile(name, belongs, isFolder).then((res) => {
      const json = JSON.parse(res.text);
      if (!isFolder) {
        // Open a newly created file
        this.onBeforeOpenFile();
      }
      EditorActions.loadFiles().then(() => {
        if (
          belongs &&
          this.props.files.find((file) => file.id === belongs)?.is_open ===
            false
        ) {
          // just open the parent because it needed to be visible to create a new file under it
          EditorActions.openFolder(belongs, true);
        }
        if (!isFolder) {
          EditorActions.openFile(json.file.id);
        }
      });
    });

  private openFolder = (id, isOpen) => {
    EditorActions.openFolder(id, isOpen);
  };

  private handleSync = (storageType) => {
    this.setState({
      syncDisable: true,
      saveDisable: true,
      compileDisable: true,
    });
    EditorActions.exportProject(storageType, 5000);
  };

  private handleUnsync = () => {
    this.setState({
      syncDisable: true,
      saveDisable: true,
      compileDisable: true,
    });
    EditorActions.unsyncProject();
  };

  private handleSaveAndCompile = (file) => {
    if (!this.props.project.permission.edit) {
      return;
    }
    if (this.state.saveDisable) {
      // saveが抑止されている状態の時は、単にコンパイルするだけでよい
      this.runCompile();
    } else {
      this.runSaveAndCompile(file, false);
    }
  };

  private handleKillCompile = () => {
    EditorActions.killCompileProcess();
  };

  private handleUpdateProject = async (params) => {
    if (this.props.project) {
      await EditorActions.updateProject(params);
    }
  };

  private handleUpdateUser = async (params) => {
    if (this.props.user && this.props.project) {
      if (this.props.user.user_setting.editor_theme === null) {
        // Initialize user_setting on the first time setting is updated
        params = {
          ...params,
          user_setting_attributes: {
            scroll_sync: this.props.project.scroll_sync,
            key_bindings: this.props.project.key_bindings,
            display_warnings: this.props.project.display_warnings,
            editor_theme: this.props.project.editor_theme,
            font_size: this.props.project.font_size,
            ...(params.user_setting_attributes || {}),
          },
        };
      }
      await EditorActions.updateUser(params);
    }
  };

  private openCountDialog = (open: boolean) => {
    EditorActions.openCountDialog(open);
  };

  private openShareDialog = (open: boolean) => {
    EditorActions.openShareDialog(open);
  };

  private createShare = () => {
    EditorActions.createShare();
  };

  private destroyShare = (token: string) => {
    EditorActions.updateShare(token, { disabled: true });
  };

  private _isCompilableFile(file) {
    // application/x-rtex はRtexのために便宜的に設けたmimetype
    return (
      file.belonging_to === null &&
      ((file.mimetype === 'application/x-tex' && /\.tex$/.test(file.name)) ||
        (file.mimetype === 'application/x-rtex' && /\.Rtex$/.test(file.name)) ||
        (file.mimetype === 'application/x-latex' && /\.ltx$/.test(file.name)) ||
        (file.mimetype === 'text/markdown' && /\.md$/.test(file.name)))
    );
  }

  private mayOpenSelectTargetDialog = () => {
    if (
      this.props.loading ||
      this.props.selectTargetDialog.open ||
      this.props.compileTargetFile
    ) {
      return;
    }
    const compilableFiles = this.props.files.filter(this._isCompilableFile);
    if (compilableFiles.length === 0) {
      return;
    }

    EditorActions.openSelectTargetDialog(compilableFiles);
  };

  private closeSelectTargetDialog = () => {
    EditorActions.closeSelectTargetDialog();
  };

  private invalidateFileCacheHandler = () => {
    EditorActions.invalidateFileCache();
  };

  private openInvalidateFileCacheDialog = (open: boolean) => {
    EditorActions.openInvalidateFileCacheDialog(open);
  };

  private closeSuccessInvalidateFileCacheDialog = () => {
    EditorActions.closeSuccessInvalidateFileCacheDialog();
  };

  private onPdfLoaded = () => {
    EditorActions.onPdfLoaded();
  };

  private onPdfLoadStarted = () => {
    EditorActions.onPdfLoadStarted();
  };

  private changeMoveDialog = (payload) => {
    EditorActions.changeMoveDialog(payload);
  };

  private handleOverwriteFile = (
    fromFileId: number,
    to: number,
  ): Promise<void> =>
    EditorActions.updateFile(fromFileId, {
      belonging_to: to,
      overwrite: true,
    }).then(() => {
      EditorActions.loadFiles();
    });

  /*
   * React component handlers
   */

  constructor(props) {
    super(props);
    this.state = {
      syncDisable: false,
      saveDisable: true,
      compileDisable: true,
    };
  }

  render() {
    return (
      <>
        <BroadcastActions primary />
        <EditorApp
          onEditorChange={this.onEditorChange}
          onBeforeOpenFile={this.onBeforeOpenFile}
          syncHandler={this.handleSync}
          unsyncHandler={this.handleUnsync}
          saveHandler={this.saveHandler}
          saveAndCompileHandler={() => {
            this.handleSaveAndCompile(this.props.currentFile);
          }}
          killCompileHandler={this.handleKillCompile}
          updateProjectHandler={this.handleUpdateProject}
          updateUserHandler={this.handleUpdateUser}
          onCloseSnackbar={EditorActions.closeSnackbar}
          openCountDialog={this.openCountDialog}
          openShareDialog={this.openShareDialog}
          closeSelectTargetDialog={this.closeSelectTargetDialog}
          createShare={this.createShare}
          destroyShare={this.destroyShare}
          invalidateFileCacheHandler={this.invalidateFileCacheHandler}
          openInvalidateFileCacheDialog={this.openInvalidateFileCacheDialog}
          closeSuccessInvalidateFileCacheDialog={
            this.closeSuccessInvalidateFileCacheDialog
          }
          onPdfLoaded={this.onPdfLoaded}
          onPdfLoadStarted={this.onPdfLoadStarted}
          changeMoveDialog={this.changeMoveDialog}
          createFile={this.createFile}
          openFolder={this.openFolder}
          handleOverwriteFile={this.handleOverwriteFile}
          {...this.props}
          {...this.state}
        />
      </>
    );
  }

  triggerRemoteEvents = (event) => {
    // Naive implementation
    if (event.changes.keys.has('compileResult')) {
      EditorActions.doneCompile(event.target.get('compileResult').result);
    } else if (
      event.changes.keys.has('loadFiles') &&
      event.target.get('loadFiles').clientID !== yDoc.clientID
    ) {
      // Temporary implementation to sync with file operations
      // TODO: Use yjs shared types instead.
      EditorActions.loadFiles(false);
    }
  };

  syncState = (event, transaction) => {
    if (event.keysChanged.has('compiling')) {
      if (event.target.get('compiling')) {
        EditorActions.requestCompileAsync();
      }
    }
  };

  componentDidMount() {
    // network event
    window.addEventListener('offline', (e) => {
      EditorActions.onOffline();
    });

    window.addEventListener('online', (e) => {
      EditorActions.onOnline();
    });

    EditorActions.loadUser();
    EditorActions.loadProjectInfo().then(() => {
      // Must wait for permission info
      EditorActions.loadFiles()
        .then(() => {
          if (this.editorContent !== '') {
            this.runCompile();
          }
        })
        .then(() => {
          // Open last opened file
          if (this.props.project?.last_opened_file_id) {
            EditorActions.openFile(this.props.project?.last_opened_file_id);
          } else {
            EditorActions.openFile(this.props.project?.compile_target_file_id);
          }
        });
    });
    addYEventHandler(this.triggerRemoteEvents);
    addYStateHandler(this.syncState);
  }

  componentWillUnmount() {
    removeYEventHandler(this.triggerRemoteEvents);
    removeYStateHandler(this.syncState);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.currentFile ||
      !this.props.currentFile ||
      nextProps.currentFile.id !== this.props.currentFile.id
    ) {
      this.setState({
        syncDisable:
          nextProps.syncing ||
          (nextProps.project &&
            nextProps.project.sync_target === 'DropboxSync'),
        compileDisable:
          nextProps.syncing ||
          !(nextProps.project && nextProps.project.compile_target_file_id),
        saveDisable: true,
      });
    } else {
      this.setState({
        syncDisable:
          nextProps.syncing ||
          (nextProps.project &&
            nextProps.project.sync_target === 'DropboxSync'),
        compileDisable:
          nextProps.syncing ||
          !(nextProps.project && nextProps.project.compile_target_file_id),
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (!prevProps.project && this.props.project) {
      EditorActions.fetchCompileResult();

      if (this.props.project.collab) {
        // Initialize
        const provider = websocketProvider();
        provider.awareness.on('change', ({ added, updated, removed }, s) => {
          if (
            added
              .concat(updated)
              .concat(removed)
              .findIndex((id) => id !== provider.awareness.clientID) >= 0
          ) {
            EditorActions.changeAwarenessState(
              Object.fromEntries(provider.awareness.getStates()),
            );
          }
        });
        if (!this.props.project?.permission.edit) {
          provider.awareness.setLocalState(null);
        } else {
          provider.awareness.setLocalState({});
        }
      }
    }

    if (!prevProps.user && this.props.user) {
      const provider = websocketProvider();
      if (!this.props.project?.permission.edit) {
        provider.awareness.setLocalState(null);
      } else {
        provider.awareness.setLocalState({
          user: { name: this.props.user.name },
        });
      }
    }
    // When anything changed except for select target dialog state
    if (prevProps.selectTargetDialog === this.props.selectTargetDialog) {
      this.mayOpenSelectTargetDialog();
    }
  }
}

const mapStateToProps = (state) => {
  const currentFile = getCurrentFile(state.editor);
  const compileTargetFile =
    state.editor.files.length &&
    state.editor.project?.compile_target_file_id &&
    getFileById(state.editor, state.editor.project?.compile_target_file_id);

  return {
    ...state.editor,
    currentFile,
    compileTargetFile,
  };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorBoundaryFallback(EditorAppContainer));
