import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import LoadingDialog from '../../LoadingDialog';
import { t } from '../../../i18n';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  fileName: string;
}

function OverwriteDialog({ open, onClose, onConfirm, fileName }: Props) {
  const theme = useTheme();

  return (
    <LoadingDialog
      open={open}
      onClose={onClose}
      onRequestExec={onConfirm}
      waiting={false}
      execLabel={t('view:editor.dialog.overwrite')}
      title={t('view:editor.dialog.duplicate_file_title')}
      disabled={false}
      PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
    >
      <Typography
        style={{
          fontSize: '16px',
          marginBottom: '16px',
          fontFamily: 'Roboto, sans-serif',
        }}
      >
        {t('view:editor.dialog.overwrite_prompt')}
        {fileName && (
          <>
            <br />
            <span style={{ color: theme.palette.text.secondary }}>
              &quot;{fileName}&quot;
            </span>
          </>
        )}
      </Typography>
    </LoadingDialog>
  );
}

export default OverwriteDialog;
