import { editorStore as store } from '../store';

export function PermissionControlled<T extends { name: string }>(ctr: T) {
  // Please see https://cloudlatex.esa.io/posts/395 for permission specification.
  const undefinedMethods = Object.keys(ctr)
    .filter((name) => typeof ctr[name] === 'function')
    .filter((name) => !ctr[name].permissionDefined);
  if (undefinedMethods.length) {
    throw new Error(
      `Permission control decorators (@RequireRead or @RequireEdit) are needed in ${ctr.name} for the methods: ${undefinedMethods.join(', ')}`,
    );
  }
  return ctr;
}

export const SkipPermissionCheck = (target, propertyKey, descriptor) => {
  descriptor.value.permissionDefined = true;
};

export const RequireRead = (target, propertyKey, descriptor) => {
  const orig = descriptor.value;
  descriptor.value = (...args: any[]) => {
    if (store.getState().editor.project?.permission.read) {
      return orig(...args);
    }
  };
  descriptor.value.permissionDefined = true;
};

export function RequireEdit(target, propertyKey, descriptor) {
  const orig = descriptor.value;
  descriptor.value = (...args: any[]) => {
    if (store.getState().editor.project?.permission.edit) {
      return orig(...args);
    }
  };
  descriptor.value.permissionDefined = true;
}

export function RequireOwner(target, propertyKey, descriptor) {
  const orig = descriptor.value;
  descriptor.value = (...args: any[]) => {
    if (store.getState().editor.project?.permission.owner) {
      return orig(...args);
    }
  };
  descriptor.value.permissionDefined = true;
}
