import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import * as Colors from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import * as React from 'react';
import { useRef } from 'react';
import LoadingDialog from '../../LoadingDialog';
import { t } from '../../../i18n';

interface Props {
  open: boolean;
  onClose: (buttonClicked: boolean) => void;
  shares?: { token: string; authority: number; disabled: boolean }[];
  createShare: () => void;
  destroyShare: (token: string) => void;
}
interface State {
  errorText: string;
  submittable: boolean;
}

const useStyles = makeStyles({
  input: {
    '& *': {
      fontSize: '1em',
    },
    marginBottom: '.5em',
  },
  button: {
    fontSize: '1em',
    marginRight: '.5em',
  },
});

function TextFieldWithCopyButton(props) {
  const classes = useStyles();
  const ref = useRef<HTMLInputElement>();
  const copyUrl = () => {
    ref.current.select();
    document.execCommand('copy');
  };

  return (
    <>
      <TextField {...props} inputRef={ref} />
      <Button
        className={classes.button}
        color="primary"
        variant="outlined"
        onClick={copyUrl}
      >
        {t('view:editor.copy_shareable_link')}
      </Button>
    </>
  );
}

function ShareDialog(props: Props) {
  const classes = useStyles();

  const onClose = () => {
    // this.setState({ errorText: '', submittable: false });
    props.onClose(true);
  };

  const shares = (props.shares ?? []).filter(
    (share) => share.authority > 0 && !share.disabled,
  );

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      PaperProps={{ style: { width: '75%', maxWidth: '576px' } }}
    >
      <DialogTitle
        disableTypography
        children={<h3>{t('view:editor.share_with_others')}</h3>}
      />
      <DialogContent>
        <div>
          <div>
            <MuiThemeProvider theme={{}}>
              {shares.length ? (
                <>
                  <p>{t('view:editor.anyone_can_view')}</p>
                  {shares.map((share) => (
                    <div key={share.token}>
                      <TextFieldWithCopyButton
                        className={classes.input}
                        autoFocus
                        contentEditable={false}
                        helperText=""
                        error={false}
                        type="text"
                        value={`${window.location.origin}/projects/${share.token}`}
                        fullWidth
                      />
                      <Button
                        className={classes.button}
                        color="primary"
                        variant="outlined"
                        onClick={() => props.destroyShare(share.token)}
                      >
                        {t('view:editor.unpublish')}
                      </Button>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <p>{t('view:editor.not_shared')}</p>
                  <div>
                    <Button
                      className={classes.button}
                      color="primary"
                      variant="outlined"
                      onClick={props.createShare}
                    >
                      {t('view:editor.get_shareable_link')}
                    </Button>
                  </div>
                </>
              )}
            </MuiThemeProvider>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={onClose}
          color="secondary"
          disableTouchRipple
        >
          {t('view:editor.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShareDialog;
