import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';

const ListItemEx = withStyles({
  root: {
    // display: 'block',
    // justifyContent: 'normal',
    '&:hover': { background: 'rgba(0, 0, 0, 0.1)' },
  },
  selected: { backgroundColor: 'rgba(0, 0, 0, 0.1) !important' },
})(ListItem);

interface Props {
  isFolder?: boolean;
  isDraggable: boolean;
  onDraggableStart: (e, ui) => void;
  component: React.ElementType<ListItemProps>;
  style: React.CSSProperties;
  onContextMenu: (e) => void;
  onClick: (e, flg?: boolean) => void;
  className: string;
  isOpen?: boolean;
  ContainerProps?: { className?: string; style?: React.CSSProperties };
  onDroppableDrop?: (e, ui) => void;
  selected?: boolean;
}
class FileListItem extends React.Component<
  Props,
  { disabledForOneCycleToFixFocusVisible: boolean }
> {
  private _elemId = uuidv4();

  constructor(props) {
    super(props);

    // If you focus on this element by keyboard interaction, Mui adds focusVisible state to show you that it is focused,
    // but the element is blurred a moment later it is focused, focusVisible state is not removed because of a bug.
    // Add disabled attribute for a single React lifecycle in order to remove this state forcibly on blur.
    this.state = { disabledForOneCycleToFixFocusVisible: false };
  }

  componentDidMount() {
    const draggableOpts: {
      addClasses: boolean;
      containment: string;
      cursor: string;
      helper: string;
      scroll: boolean;
      opacity: number;
      revert: string;
      distance: number;
      start?: (e, ui) => void;
    } = {
      addClasses: false,
      containment: '#file_list_content',
      cursor: 'move',
      helper: 'original',
      scroll: false,
      opacity: 0.3,
      revert: 'invalid',
      distance: 10,
    };
    if (this.props.onDraggableStart) {
      draggableOpts.start = this.props.onDraggableStart;
    }
    let fileListItemElement: Element = document.getElementById(this._elemId);
    // See https://github.com/cloudlatex-team/cloudlatex2/issues/1683
    if (fileListItemElement.nodeName.toLowerCase() !== 'li') {
      fileListItemElement = fileListItemElement.parentElement;
    }
    if (this.props.isDraggable) {
      const $jQueryfileListItemElement: JQuery<Element> =
        $(fileListItemElement);
      $jQueryfileListItemElement.draggable(draggableOpts);
      if (this.props.isFolder) {
        const droppableOpts = {
          addClasses: false,
          greedy: true,
          tolerance: 'pointer',
          drop: this.props.onDroppableDrop,
        };
        const $jQueryfolderElement: JQuery<Element> =
          $jQueryfileListItemElement;
        $jQueryfolderElement.droppable(droppableOpts);
      }
    }
  }

  addDisabledOnBlur = () => {
    this.setState({ disabledForOneCycleToFixFocusVisible: true });
  };

  // Remove disabled right after a single React lifecycle to just remove focusVisible state from ListItem
  removeDisabledAfterOneLifeCycle = () => {
    if (this.state.disabledForOneCycleToFixFocusVisible) {
      this.setState({ disabledForOneCycleToFixFocusVisible: false });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{ disabledForOneCycleToFixFocusVisible: boolean }>,
    snapshot?: any,
  ) {
    this.removeDisabledAfterOneLifeCycle();
  }

  render() {
    const {
      isFolder,
      isOpen,
      onClick,
      onDraggableStart,
      onDroppableDrop,
      selected,
      isDraggable,
      ...other
    } = this.props;

    return (
      <ListItemEx
        button
        id={this._elemId}
        onClick={(e) => {
          if (e.nativeEvent.button === 2) return;
          if (isFolder) {
            const updatedState = !isOpen;
            onClick && onClick(e, updatedState);
          } else {
            onClick && onClick(e);
          }
        }}
        disabled={this.state.disabledForOneCycleToFixFocusVisible}
        onBlur={this.addDisabledOnBlur}
        selected={selected}
        {...other}
      />
    );
  }
}

export default FileListItem;
