import * as Y from 'yjs';
import {
  EditorView,
  layer,
  LayerMarker,
  RectangleMarker,
  ViewPlugin,
  ViewUpdate,
} from '@codemirror/view';
import { YSyncConfig, ySyncFacet } from 'y-codemirror.next';
import {
  Annotation,
  EditorSelection,
  SelectionRange,
  StateField,
} from '@codemirror/state';
import { compareRelativePositions } from 'yjs';

// See also https://github.com/yjs/y-codemirror.next/blob/v0.3.5/src/y-remote-selections.js
// Overwritten to use layer instead of widget because it caused line wrapping and scrolling problems.

type yRemoteSelectionStateValue = {
  selections: {
    color: string;
    anchor: Y.RelativePosition;
    head: Y.RelativePosition;
  }[];
  cursors: {
    color: string;
    name: string;
    side: -1 | 1;
    head: Y.RelativePosition;
  }[];
};

const yRemoteSelectionsAnnotation =
  Annotation.define<yRemoteSelectionStateValue>();

class YRemoteCursor implements LayerMarker {
  constructor(
    private readonly color,
    private readonly name,
    private readonly top,
    private readonly left,
  ) {
    this.color = color;
    this.name = name;
    this.top = top;
    this.left = left;
  }

  eq(c) {
    return (
      this.color === c.color &&
      this.name === c.name &&
      this.top === c.top &&
      this.left === c.left
    );
  }

  draw() {
    const caret = document.createElement('span');
    caret.classList.add('cm-ySelectionCaret');
    caret.style.backgroundColor = this.color;
    caret.style.borderColor = this.color;

    const dot = document.createElement('div');
    dot.classList.add('cm-ySelectionCaretDot');

    caret.append(dot);
    let info;

    function leave(target) {
      if (
        target.relatedTarget === caret ||
        target.relatedTarget === dot ||
        target.relatedTarget === info
      ) {
        return;
      }
      info && (info.style.opacity = 0);
      setTimeout(() => info?.remove(), 300);
    }

    const hover = () => {
      if (!info) {
        info = document.createElement('div');
        info.classList.add('cm-ySelectionInfo');
        info.innerText = this.name;
        info.addEventListener('mouseleave', leave);
        info.style.transition = 'opacity .3s ease-in-out';
        info.style.position = 'absolute';
        info.style.backgroundColor = this.color;
        info.style.color = 'white';
        info.style.opacity = 0;
        info.style.fontSize = '.75em';
        info.style.paddingInline = '2px';
        info.style.whiteSpace = 'nowrap';
      }

      document.body.append(info);

      // Calculate info label size after appending
      setTimeout(() => {
        const caretRect = caret.getBoundingClientRect();
        const infoRect = info.getBoundingClientRect();
        if (caretRect) {
          info.style.top = `${caretRect.top - infoRect.height}px`;
          if (caretRect.right + infoRect.width > window.innerWidth) {
            info.style.left = `${caretRect.right - infoRect.width}px`;
          } else {
            info.style.left = `${caretRect.left}px`;
          }
          info.style.display = 'block';
          // Enable transition
          setTimeout(() => (info.style.opacity = 1), 0);
        } else {
          info.style.display = 'none';
        }
      }, 0);
    };
    caret.addEventListener('mouseenter', hover);
    dot.addEventListener('mouseenter', hover);
    caret.addEventListener('mouseleave', leave);
    dot.addEventListener('mouseleave', leave);

    return caret;
  }

  update(dom, oldMarker) {
    if (oldMarker.color !== this.color) {
      return false;
    }
    dom.style.top = `${this.top}px`;
    dom.style.left = `${this.left}px`;
    return true;
  }
}

// Add color to RectangleMarker
class ColoredRectangleMarker implements LayerMarker {
  constructor(
    private readonly color: string,
    private readonly rectangleMarker: RectangleMarker,
  ) {
    this.color = color;
    this.rectangleMarker = rectangleMarker;
  }

  draw() {
    const dom = this.rectangleMarker.draw();
    dom.style.backgroundColor = this.color;
    return dom;
  }

  update(dom: HTMLElement, prev: ColoredRectangleMarker) {
    if (prev.color !== this.color) return false;
    return this.rectangleMarker.update(dom, prev.rectangleMarker);
  }

  eq(p: ColoredRectangleMarker) {
    return this.rectangleMarker.eq(p.rectangleMarker) && this.color === p.color;
  }

  static wrap(
    color: string,
    markers: readonly RectangleMarker[],
  ): ColoredRectangleMarker[] {
    return markers.map((m) => new ColoredRectangleMarker(color, m));
  }
}

const yRemoteAwarenessField = StateField.define<yRemoteSelectionStateValue>({
  create(state) {
    return {
      selections: [],
      cursors: [],
    };
  },
  update(value, tr) {
    return tr.annotation(yRemoteSelectionsAnnotation) ?? value;
  },
});

const yAwarenessPlugin = ViewPlugin.fromClass(
  class {
    view: EditorView;

    conf: YSyncConfig;

    _awareness;

    constructor(view: EditorView) {
      this.view = view;
      this.conf = view.state.facet(ySyncFacet);
      this._awareness = this.conf.awareness;
      this._awareness.on('change', this.listener);
    }

    destroy() {
      this._awareness.off('change', this.listener);
    }

    listener = ({ added, updated, removed }, s, t) => {
      const clients = added.concat(updated).concat(removed);
      const ytext = this.conf.ytext;
      const ydoc = this.conf.awareness.doc;
      if (
        clients.findIndex((id) => id !== this.conf.awareness.doc.clientID) >= 0
      ) {
        const selections = [];
        const cursors = [];
        this._awareness.getStates().forEach((state, clientid) => {
          if (clientid === this._awareness.doc.clientID) {
            return;
          }
          const cursor = state.cursor;
          if (cursor == null || cursor.anchor == null || cursor.head == null) {
            return;
          }
          const anchor = Y.createAbsolutePositionFromRelativePosition(
            cursor.anchor,
            ydoc,
          );
          const head = Y.createAbsolutePositionFromRelativePosition(
            cursor.head,
            ydoc,
          );
          if (
            anchor == null ||
            head == null ||
            anchor.type !== ytext ||
            head.type !== ytext
          ) {
            return;
          }
          const { color = '#30bced', name = 'Anonymous' } = state.user || {};
          const colorLight =
            (state.user && state.user.colorLight) || `${color}33`;

          if (anchor.index !== head.index) {
            selections.push({
              color: colorLight,
              head: cursor.head,
              anchor: cursor.anchor,
            });
          }
          cursors.push({
            color,
            name,
            side: head.index - anchor.index > 0 ? -1 : 1,
            head: cursor.head,
          });
        });
        this.view.dispatch({
          annotations: [
            yRemoteSelectionsAnnotation.of({ selections, cursors }),
          ],
        });
      }
    };

    update(update: ViewUpdate) {
      const ytext = this.conf.ytext;
      const awareness = this.conf.awareness;

      const localAwarenessState = this.conf.awareness.getLocalState();

      // set local awareness state (update cursors)
      if (localAwarenessState != null) {
        const hasFocus =
          update.view.hasFocus && update.view.dom.ownerDocument.hasFocus();
        const sel = hasFocus ? update.state.selection.main : null;
        const currentAnchor =
          localAwarenessState.cursor == null
            ? null
            : Y.createRelativePositionFromJSON(
                localAwarenessState.cursor.anchor,
              );
        const currentHead =
          localAwarenessState.cursor == null
            ? null
            : Y.createRelativePositionFromJSON(localAwarenessState.cursor.head);

        if (sel != null) {
          // 範囲を内側に固定する。自分と他のカーソルが同じ場所にあるとき、自分が後ろ側にあることになる。
          // よって他人に送信するカーソルは前側に固定する。
          // 選択範囲のスタートは後ろ側に固定する。
          const side = sel.head - sel.anchor;
          const anchor = Y.createRelativePositionFromTypeIndex(
            ytext,
            sel.anchor,
            side || -1,
          );
          const head = Y.createRelativePositionFromTypeIndex(
            ytext,
            sel.head,
            -side || -1,
          );
          if (
            localAwarenessState.cursor == null ||
            !Y.compareRelativePositions(currentAnchor, anchor) ||
            !Y.compareRelativePositions(currentHead, head)
          ) {
            awareness.setLocalStateField('cursor', {
              anchor,
              head,
            });
          }
        } else if (localAwarenessState.cursor != null && hasFocus) {
          awareness.setLocalStateField('cursor', null);
        }
      }
    }
  },
);

export const YRemoteSelections = [
  yRemoteAwarenessField,
  yAwarenessPlugin,
  layer({
    above: false,
    update(update, layer) {
      return !!update.transactions.find((tr) =>
        tr.annotation(yRemoteSelectionsAnnotation),
      );
    },
    markers(view) {
      const { selections } = view.state.field(yRemoteAwarenessField);
      return selections
        .map((selection) => {
          const conf = view.state.facet(ySyncFacet);
          const anchor = Y.createAbsolutePositionFromRelativePosition(
            selection.anchor,
            conf.awareness.doc,
          );
          const head = Y.createAbsolutePositionFromRelativePosition(
            selection.head,
            conf.awareness.doc,
          );
          const range = EditorSelection.range(anchor.index, head.index);
          return ColoredRectangleMarker.wrap(
            selection.color,
            RectangleMarker.forRange(view, 'cm-ySelection', range),
          );
        })
        .flat();
    },
  }),
  layer({
    above: true,
    update(update, layer) {
      return !!update.transactions.find((tr) =>
        tr.annotation(yRemoteSelectionsAnnotation),
      );
    },
    markers(view) {
      const { cursors } = view.state.field(yRemoteAwarenessField);
      const conf = view.state.facet(ySyncFacet);
      return cursors.map((cursor) => {
        const head = Y.createAbsolutePositionFromRelativePosition(
          cursor.head,
          conf.awareness.doc,
        );
        const rect = view.coordsAtPos(head.index, cursor.side);
        const contentRect = view.contentDOM.getBoundingClientRect();
        const scrollRect = view.scrollDOM.getBoundingClientRect();
        return new YRemoteCursor(
          cursor.color,
          cursor.name,
          rect.top - contentRect.top,
          rect.left - scrollRect.left + view.scrollDOM.scrollLeft * view.scaleX,
        );
      });
    },
  }),
];
