import { useCallback } from 'react';
import * as ReactDOM from 'react-dom';
import {
  closeSearchPanel,
  findNext,
  findPrevious,
  getSearchQuery,
  replaceAll,
  replaceNext,
  SearchQuery,
  selectMatches,
  setSearchQuery,
} from '@codemirror/search';
import {
  EditorView,
  Panel,
  runScopeHandlers,
  ViewUpdate,
} from '@codemirror/view';

type Props = {
  view: EditorView;
  query: SearchQuery;
};

export function SearchForm({ view, query }: Props) {
  const commit = useCallback(
    (e) => {
      const newQuery = new SearchQuery({
        search: e.target.name === 'search' ? e.target.value : query.search,
        caseSensitive:
          e.target.name === 'case' ? e.target.checked : query.caseSensitive,
        regexp: e.target.name === 're' ? e.target.checked : query.regexp,
        wholeWord:
          e.target.name === 'word' ? e.target.checked : query.wholeWord,
        replace: e.target.name === 'replace' ? e.target.value : query.replace,
        literal: e.target.name === 'literal' ? e.target.checked : query.literal,
      });
      if (!newQuery.eq(query)) {
        view.dispatch({ effects: setSearchQuery.of(newQuery) });
      }
    },
    [view, query],
  );

  const keydown = useCallback(
    (e) => {
      if (runScopeHandlers(view, e, 'search-panel')) {
        e.preventDefault();
      } else if (e.keyCode === 13 && e.target.name === 'search') {
        e.preventDefault();
        (e.shiftKey ? findPrevious : findNext)(view);
      } else if (e.keyCode === 13 && e.target.name === 'replace') {
        e.preventDefault();
        replaceNext(view);
      }
    },
    [view],
  );

  return (
    <div
      onKeyDown={keydown}
      style={{ display: 'grid', gridTemplateColumns: 'min-content 1fr' }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'subgrid',
          gridRow: 'span 2',
        }}
      >
        <div>
          <input
            value={query.search}
            placeholder={view.state.phrase('Find')}
            aria-label={view.state.phrase('Find')}
            className="cm-textfield"
            name="search"
            form=""
            /* eslint-disable-next-line react/no-unknown-property */
            main-field="true"
            onChange={commit}
            onKeyUp={commit}
          />
        </div>
        {!view.state.readOnly && (
          <div>
            <input
              value={query.replace}
              placeholder={view.state.phrase('Replace')}
              aria-label={view.state.phrase('Replace')}
              className="cm-textfield"
              name="replace"
              form=""
              onChange={commit}
              onKeyUp={commit}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'subgrid',
          gridRow: 'span 2',
        }}
      >
        <div>
          <button
            className="cm-button"
            name="next"
            onClick={() => findNext(view)}
          >
            {view.state.phrase('next')}
          </button>
          <button
            className="cm-button"
            name="prev"
            onClick={() => findPrevious(view)}
          >
            {view.state.phrase('previous')}
          </button>
          <button
            className="cm-button"
            name="select"
            onClick={() => selectMatches(view)}
          >
            {view.state.phrase('all')}
          </button>
          <label htmlFor="cm_search_case">
            <input
              type="checkbox"
              name="case"
              form=""
              id="cm_search_case"
              checked={query.caseSensitive}
              onChange={commit}
            />
            {view.state.phrase('match case')}
          </label>
          <label htmlFor="cm_search_re">
            <input
              type="checkbox"
              name="re"
              form=""
              id="cm_search_re"
              checked={query.regexp}
              onChange={commit}
            />
            {view.state.phrase('regexp')}
          </label>
          <label htmlFor="cm_search_word">
            <input
              type="checkbox"
              name="word"
              form=""
              id="cm_search_word"
              checked={query.wholeWord}
              onChange={commit}
            />
            {view.state.phrase('by word')}
          </label>
        </div>
        {!view.state.readOnly && (
          <div>
            <button
              className="cm-button"
              name="replace"
              onClick={() => replaceNext(view)}
            >
              {view.state.phrase('replace')}
            </button>
            <button
              className="cm-button"
              name="replaceAll"
              onClick={() => replaceAll(view)}
            >
              {view.state.phrase('replace all')}
            </button>
          </div>
        )}
      </div>
      <button
        name="close"
        onClick={() => closeSearchPanel(view)}
        aria-label={view.state.phrase('close')}
      >
        ×
      </button>
    </div>
  );
}

export class SearchPanel implements Panel {
  dom: HTMLElement;

  top: boolean = true;

  constructor(readonly view) {
    this.dom = document.createElement('div');
    this.dom.classList.add('cm-search');
  }

  update(update: ViewUpdate) {
    for (const tr of update.transactions) {
      for (const effect of tr.effects) {
        if (effect.is(setSearchQuery)) {
          ReactDOM.render(
            <SearchForm
              view={update.view}
              query={getSearchQuery(update.state)}
            />,
            this.dom,
          );
        }
      }
    }
  }

  mount() {
    ReactDOM.render(
      <SearchForm view={this.view} query={getSearchQuery(this.view.state)} />,
      this.dom,
    );
    (this.dom.querySelector('[main-field]') as HTMLInputElement)?.select();
  }
}
