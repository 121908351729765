import * as Y from 'yjs';
import { WebsocketProvider } from '@y-rb/actioncable';
import { yCollab as yCollabOrig } from 'y-codemirror.next';
import consumer from '../channels/consumer';
import { YRemoteSelections } from './yRemoteSelections';

let match;
let provider: WebsocketProvider;

export const yDoc = new Y.Doc();
export function websocketProvider() {
  if (
    !provider &&
    (match = window.location.pathname.match(/^\/projects\/([^\/]+)/))
  ) {
    provider = new WebsocketProvider(
      yDoc,
      consumer,
      'CollaborationChannel',
      { id: match[1] },
      { disableBc: true },
    );
    setInterval(() => {
      if (provider?.synced) {
        provider.channel.perform('ping');
      }
    }, 60000);
  }

  return provider;
}

export function setYState(name, payload) {
  yDoc.getMap('state').set(name, payload);
}

export function emitYEvent(name, payload) {
  yDoc.getMap('events').set(name, payload);
}

export async function addYEventHandler(handler) {
  // Do not process old events
  await yDoc.whenLoaded;
  yDoc.getMap('events').observe(handler);
}

export async function removeYEventHandler(handler) {
  await yDoc.whenLoaded;
  yDoc.getMap('events').unobserve(handler);
}

export function addYStateHandler(handler) {
  yDoc.getMap('state').observe(handler);
}

export function removeYStateHandler(handler) {
  yDoc.getMap('state').unobserve(handler);
}

addYStateHandler((event) => {
  if (event.keysChanged.has('load')) {
    yDoc.emit('load', [yDoc]);
  }
});

// Override awareness plugin
export const yCollab = (ytext, awareness, options) => {
  const plugins = yCollabOrig(ytext, awareness, options);

  if (awareness) {
    plugins[3] = YRemoteSelections;
  }

  return plugins;
};
